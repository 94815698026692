<template>
  <div>
    <CRow>
      <CCol cols="12" lg="12" md="12">
        <CCard class="todo_list_items d-block mb-0">
          <CCardHeader>
            <CRow>
              <CCol cols="5" xl="5" class="pt-0 pb-0 text-left">
                {{ $t("todo.Items") }} {{todoList.name}} <span v-if="todoList.items.length > 0">({{todoList.items.length}})</span>
              </CCol>
              <CCol cols="7" xl="7" class="pt-0 pb-0 text-xl-right">
                <div class="mt-1 mt-xl-0 d-inline-block align-top">
                  <CButton class="ml-2 link" color="primary" @click="newTodoListItemModal = true; resetNewItemData();">
                    <i class="fas fa-plus mr-1"/>
                    <span>{{ $t('todo.Add_item') }}</span>
                  </CButton>
                </div> 
              </CCol>              
            </CRow>
          </CCardHeader>
          <CCardBody class="p-0">             
            <CRow>
              <CCol cols="12" sm="12" md="12" lg="12" class="pt-0" v-bind:class="{'pb-0' : !isPaginated}">
                <loadingSpinner mode="auto" v-if="!dataLoaded" :content="$t('common.Loading')"/>
                <div v-else>                
                  <b-table ref="itemTable" class="data_table" :data="todoList.items" :striped="true" :hoverable="true" :mobile-cards="true" :paginated="isPaginated" :per-page="perPage" :current-page.sync="currentPage" :pagination-simple="isPaginationSimple" :pagination-position="paginationPosition">
                    <template slot-scope="item">
                      <b-table-column field="item_order" :label="$t('todo.Item_order')">
                        <span>{{item.row.item_order + 1}}</span>                                                 
                      </b-table-column>                                                
                      <b-table-column field="title" :label="$t('todo.Item_title')">
                        <div v-if="item.row.item_finished === false">
                          <quick-edit type="text" mode="ignore" :buttonOkText="$t('ok')" :buttonCancelText="$t('cancel')" v-model="item.row.title" v-on:input="updateTodoListItem(item.row.todo_list_item_id_external, item.row)">
                            <span>{{item.row.title !== null ? item.row.title : $t('todo.Click_to_add_title')}}<i class="icon edit fas fa-pen ml-1"/></span>
                          </quick-edit>
                        </div>
                        <div v-else>
                          <span>{{item.row.title}}</span>
                        </div>
                      </b-table-column>
                      <b-table-column field="assignee" :label="$t('todo.Assignee')">
                        <div v-if="item.row.item_finished === false">
                          <quick-edit type="select" :options="todoList.users" mode="ignore" :buttonOkText="$t('ok')" :buttonCancelText="$t('cancel')" v-model="item.row.assignee_user_id_external" v-on:input="updateTodoListItem(item.row.todo_list_item_id_external, item.row)">                            
                            <div v-if="item.row.assignee_user_id_external !== null && item.row.assignee_profile_image !== null" class="profile_image d-inline-block align-middle mr-2" v-bind:style="{ backgroundImage: 'url(' + apiBaseUrl + '/v1/common/cdn/file/image/core-user-profile/' + item.row.assignee_user_id_external + '/' + item.row.assignee_profile_image + '/' + clientToken + ')' }"></div>
                            <div v-else-if="item.row.assignee_user_id_external !== null && item.row.assignee_profile_image === null" class="profile_icon d-inline-flex align-middle mr-2">
                              <userProfileAvatar :username="item.row.assignee_label" :size="25"/>
                            </div>                          
                            <span>{{item.row.assignee_label !== null ? item.row.assignee_label : $t('todo.Click_to_assign')}}<i class="icon edit fas fa-pen ml-1"/></span>
                          </quick-edit> 
                        </div>
                        <div v-else>
                          <div v-if="item.row.assignee_user_id_external !== null && item.row.assignee_profile_image !== null" class="profile_image d-inline-block align-middle mr-2" v-bind:style="{ backgroundImage: 'url(' + apiBaseUrl + '/v1/common/cdn/file/image/core-user-profile/' + item.row.assignee_user_id_external + '/' + item.row.assignee_profile_image + '/' + clientToken + ')' }"></div>
                          <div v-else-if="item.row.assignee_user_id_external !== null && item.row.assignee_profile_image === null" class="profile_icon d-inline-flex align-middle mr-2">
                            <userProfileAvatar :username="item.row.assignee_label" :size="25"/>
                          </div>
                          <span>{{item.row.assignee_label}}</span>
                        </div>
                      </b-table-column>
                      <b-table-column field="ended" :label="$t('todo.Est_duration')">
                        <span v-if="item.row.estimated_duration !== null">{{item.row.estimated_duration}} {{ $t('common.minutes') }}</span>
                        <span v-else>-</span>
                      </b-table-column> 
                      <b-table-column field="started" :label="$t('todo.Started')">
                        <span v-if="item.row.started !== null">{{item.row.started | moment('DD-MM-YYYY HH:mm')}}</span>
                        <span v-else>-</span>
                      </b-table-column>
                      <b-table-column field="ended" :label="$t('todo.Finished')">
                        <span v-if="item.row.ended !== null">{{item.row.ended | moment('DD-MM-YYYY HH:mm')}}</span>
                        <span v-else>-</span>
                      </b-table-column>
                      <b-table-column field="last_update" :label="$t('todo.Last_update')">
                        <span v-if="item.row.updated !== null">{{item.row.updated | moment('DD-MM-YYYY HH:mm')}} {{$t('by')}} {{item.row.last_updated_by}}</span>
                        <span v-else>-</span>
                      </b-table-column>
                      <b-table-column field="actions" width="5%">      
                        <div class="d-flex justify-content-lg-end">                    
                          <CButton class="d-inline-block" color="primary" @click="confirmModal = true; confirmModalData = item.row;">
                            <i class="fas fa-times"/>
                          </CButton>
                        </div>
                      </b-table-column>                        
                    </template>
                    <template slot="empty">
                      <div class="text-center">
                        {{ $t('todo.No_items_yet') }}
                      </div>                
                    </template>                
                  </b-table>
                </div>
              </CCol>                     
            </CRow>                                                 
          </CCardBody>     
        </CCard>        
      </CCol>
    </CRow>

    <b-modal class="todo_list" :can-cancel="[]" :active.sync="newTodoListItemModal" :width="960" scroll="keep">
      <CCard class="mb-0">
        <CCardHeader class="pb-0">
          {{ $t('todo.New_item') }}
        </CCardHeader>
        <CCardBody>
          <CRow>
            <CCol cols="12" lg="12" class="pt-0 pb-0">
              <CInput type="text" :label="$t('todo.Item_title')" class="mb-0" v-model="newTodoListItem.title" required was-validated/>
            </CCol>            
          </CRow>            
        </CCardBody>
        <CCardFooter>
          <div>
            <CButton color="primary" @click="insertTodoListItem();"><i class="fas fa-save mr-1"/>{{ $t('Save') }}</CButton>
            <CButton color="secondary" @click="newTodoListItemModal = false; resetNewItemData();"><i class="fas fa-times mr-1"/>{{ $t('cancel') }}</CButton>
          </div>            
        </CCardFooter>          
      </CCard>
    </b-modal>

    <b-modal class="rewards" :can-cancel="['x']" :active.sync="confirmModal" :width="960" scroll="keep">
      <CCard class="mb-0">
        <CCardHeader class="pb-0">
          {{ $t('Delete ') }} {{confirmModalData.title}}
        </CCardHeader>
        <CCardBody>
          <CRow>
            <CCol cols="12" lg="12" class="pt-0 pb-0">
              <p class="mb-2">{{ $t('todo.sure_to_delete') }} {{confirmModalData.title}}?</p>
            </CCol>
          </CRow>    
        </CCardBody>
        <CCardFooter>
          <CButton color="primary" @click="deleteTodoListItem(confirmModalData.todo_list_item_id_external)"><i class="fas fa-check mr-1"/>{{ $t('confirm') }}</CButton>
          <CButton color="secondary" @click="confirmModal = false"><i class="fas fa-times mr-1"/>{{ $t('cancel') }}</CButton>
        </CCardFooter>          
      </CCard>
    </b-modal>     

  </div>
</template>

<script>
import axios from 'axios';
import Multiselect from 'vue-multiselect';
import QuickEdit from 'vue-quick-edit';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import loadingSpinner from '@/components/common/loadingSpinner.vue'
import userProfileAvatar from "@/components/common/userProfileAvatar.vue";

export default {
  name: 'Group',
  components: {
    Multiselect,
    QuickEdit,
    loadingSpinner,
    userProfileAvatar
  },
  data() {
    return {
      apiBaseUrl: null,
      clientToken: null,
      todoListIdExternal: null,
      todoList: {
        items: [],
        users: []
      },
      dataLoaded: false,
      isPaginated: true,
      isPaginationSimple: false,
      paginationPosition: 'bottom',
      currentPage: 1,
      perPage: 15,            
      listViewEnabled: false,
      editor: ClassicEditor,
      editorConfig: {
        toolbar: [ 'heading', '|', 'bold', 'italic', 'bulletedList', 'numberedList', '|', 'undo', 'redo' ]
      },
      newTodoListItemModal: false,
      newTodoListItem: {},
      confirmModal: false,
      confirmModalData: {}
    }
  },
  methods: {
    getTodoListDetails() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/hub/todo/list/' + this.todoListIdExternal + '/overview')
      .then(res => {
        this.todoList = res.data.data;
        // Check if the pagination should be activated
        (this.todoList.items.length > this.perPage) ? this.isPaginated = true : this.isPaginated = false;

        this.dataLoaded = true;
      })
      .catch(err => {
        console.error(err); 
      });
    },    
    getTodoListItemDetails(todoListItemId) {
      axios.get(process.env.VUE_APP_API_URL + '/v1/hub/todo/item/' + todoListItemId)
      .then(res => {
        let updatedTodoListItem = res.data.data;

        // Check if table refs are available
        if(this.$refs !== undefined && this.$refs.itemTable !== undefined ) {

          let todoListItemIndex = this.$refs.itemTable.data.findIndex(x => x.todo_list_item_id_external == updatedTodoListItem.todo_list_item_id_external);

          this.$refs.itemTable.data[todoListItemIndex].title = updatedTodoListItem.title;
          this.$refs.itemTable.data[todoListItemIndex].assignee_name = updatedTodoListItem.assignee_name;
          this.$refs.itemTable.data[todoListItemIndex].assignee_label = updatedTodoListItem.assignee_label;
          this.$refs.itemTable.data[todoListItemIndex].assignee_profile_image = updatedTodoListItem.assignee_profile_image;
          this.$refs.itemTable.data[todoListItemIndex].assignee_user_id_external = updatedTodoListItem.assignee_user_id_external;
          this.$refs.itemTable.data[todoListItemIndex].updated = updatedTodoListItem.updated;
          this.$refs.itemTable.data[todoListItemIndex].last_updated_by = updatedTodoListItem.last_updated_by;
        }        
      })
      .catch(err => {
        console.error(err); 
      });
    },
    insertTodoListItem() {
      let params = {};
      params = this.newTodoListItem;

      axios.post('v1/hub/todo/list/' + this.todoListIdExternal + '/item', params)
      .then(res => {
        this.getTodoListDetails();
        // Close the modal
        this.newTodoListItemModal = false;        
      })
      .catch(err => {
        console.error(err); 
      });      
    },    
    updateTodoListItem(todoListItemId, data) {
      let params = {}
      params = data;
              
      axios.put(process.env.VUE_APP_API_URL + '/v1/hub/todo/item/' + todoListItemId, params)
      .then(res => {
        this.$buefy.toast.open({ message: this.$t('todo.Item_updated'), type: 'is-success', duration: 3000 });
        // Update the todo list details
        this.getTodoListItemDetails(todoListItemId);
      })
      .catch(err => {
        console.error(err); 
        this.$buefy.toast.open({ message: this.$t('error_alert_text'), type: 'is-danger', duration: 2000 });
      })   
    },    
    deleteTodoListItem(todoListItemId) {
      axios.delete('v1/hub/todo/item/' + todoListItemId)
      .then(res => {
        this.getTodoListDetails();
        // Close the modal
        this.confirmModal = false;
      })
      .catch(err => {
        console.error(err); 
      });
    },
    resetNewItemData() {
      this.newTodoListItem = { title: '' }
    }          
  },
  mounted: function(){
    this.apiBaseUrl = process.env.VUE_APP_API_URL;
    this.clientToken = localStorage.getItem('token');  
    // Reset data
    this.resetNewItemData();    
    // Check the route param
    if(this.$route.params.id !== undefined) {
      // Set the group ID
      this.todoListIdExternal = this.$route.params.id;
      // Get the group details
      this.getTodoListDetails();      
    } else {
      // this.todoList = null;
    }
  }
}
</script>